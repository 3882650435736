import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
import * as Loadable from "react-loadable";
import loading from "../../components/Loading";
import { IAppProps } from "./IAppProps";
import ScrollToTop, { IScrollToTopProps } from "../../components/ScrollToTop";
import MiddlegroundService from "../../Service";
import Login from "../Login";
import LoginResult from "../LoginResult";
const Home = Loadable({
  loading,
  loader: () => import(/* webpackChunkName: "Home" */ "../Home")
});

class App extends React.Component<IAppProps> {
  public render() {
    const extraEvent = (props: IScrollToTopProps) => {
      MiddlegroundService.hasLoginAndOut(props);
    };
    return (
      <Router>
        <ScrollToTop extraEvent={extraEvent}>
          <Switch>
            <Route path="/login" exact={true} component={Login} />
            <Route path="/login-result" exact={true} component={LoginResult} />
            <Route component={Home} />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

export default hot(module)(App);
