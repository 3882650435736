import * as React from "react";
import classNames from "classnames";
import "antd/lib/layout/style";
import "antd/lib/message/style";
import * as styles from "./index.less";
import Layout from "antd/lib/layout";
import message from "antd/lib/message";
import Icon from "../../components/Icon";
import Logo from "../../components/Logo";
import Footer from "../../components/Footer";
import DYSwiper from "../../components/Swiper";
// import { ImportLoader } from "rb-utility";
import { RouteComponentProps } from "react-router-dom";
import { ISwiperOptions, SwiperEvents } from "../../components/Swiper/ISwiperTypes";
import { IDYLoginStateTypes, LoginType, ContextStatusType, LoginOrgType } from "./ILoginTypes";
import MiddlegroundService from "../../Service";
import { EventHelper } from "rb-utility";

type IDYLoginProps = RouteComponentProps<IDictionary>;

class Login extends React.Component<IDYLoginProps, IDYLoginStateTypes> {
  swiper: DYSwiper | undefined;
  redirect_uri = `${window.location.origin}/login-result`;
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      width: innerWidth,
      height: innerHeight,
      loginType: LoginType.quick,
      contextStatus: ContextStatusType.scan,
      orgsName: "",
      orgList: [],
      loginButtonName: LoginOrgType.login
    };
  }

  DDLogin() {
    const STATE = Date.now();
    window["DDLogin"]({
      id: "login_qrcode", // 这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
      goto: encodeURIComponent(
        `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${
          baseConfig.appId
        }&response_type=code&scope=snsapi_login&state=${STATE}&redirect_uri=${this.redirect_uri}`
      ),
      style: "border:none;background-color:#FFFFFF;",
      width: "300",
      height: "300"
    });
  }

  async componentDidMount() {
    await MiddlegroundService.hasLoginAndOut(this.props);
    await this.handleInit();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.hanndleMessage, false);
  }

  async handleInit() {
    window.addEventListener("message", this.hanndleMessage, false);
    this.DDLogin();
  }

  hanndleMessage = async (event: MessageEvent) => {
    const origin = event.origin;
    if (origin === "https://login.dingtalk.com") {
      // 判断是否来自ddLogin扫码事件。
      let loginTmpCode = event.data; // 拿到loginTmpCode后就可以在这里构造跳转链接进行跳转了
      const STATE = Date.now();
      const href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${
        baseConfig.appId
      }&response_type=code&scope=snsapi_login&state=${STATE}&redirect_uri=${
        this.redirect_uri
      }&loginTmpCode=${loginTmpCode}`;
      this.setState({ loginResultUrl: href });
    } else if (origin === window.location.origin) {
      const data = event.data;
      if (typeof data === "object") {
        if (data.code) {
          this.oauthLogin(data.code);
        }
      }
    }
  };

  @EventHelper.exec(message)
  private async oauthLogin(code: string) {
    // 判断是否来自钉钉扫码成功后回调事件。
    const result = await MiddlegroundService.oauthLogin(code);
    let { userTenants } = result;
    userTenants = userTenants ? userTenants : [];
    const isUserTenants = userTenants.length <= 0;
    const loginButtonName = isUserTenants ? LoginOrgType.registered : LoginOrgType.login;
    const orgsName = isUserTenants ? "请创建一个新的企业组织" : userTenants[0].name;
    const tenantId = isUserTenants ? "" : userTenants[0].id;
    this.setState({
      contextStatus: ContextStatusType.success,
      orgList: userTenants,
      loginButtonName,
      tenantId,
      orgsName
    });
  }

  loginSwitch = () => {
    const { loginType } = this.state;
    if (loginType === LoginType.quick) {
      this.setState({ loginType: LoginType.static });
    } else {
      this.setState({ loginType: LoginType.quick }, () => {
        this.DDLogin();
      });
    }
  };

  handleLoginClick = async () => {
    const { loginButtonName, orgsName, tenantId } = this.state;
    if (loginButtonName === LoginOrgType.login) {
      if (tenantId) {
        this.login(tenantId, orgsName);
      }
    } else {
      this.props.history.push(`/registered`);
    }
  };

  @EventHelper.exec(message)
  private async login(tenantId: string, orgsName?: string) {
    await MiddlegroundService.Login(tenantId, orgsName);
    this.props.history.push(`/`);
  }

  handleReturnClick = async () => {
    await MiddlegroundService.handleLoginOut(this.props);
    this.setState({ loginType: LoginType.quick, contextStatus: ContextStatusType.scan }, () => {
      this.DDLogin();
    });
  };

  handleAddLoginOrg = () => {
    if (this.swiper) if (this.swiper.swiper) this.swiper.swiper.slideNext();
  };

  renderLogin = () => {
    const { loginType } = this.state;
    return (
      <div className={styles.login}>
        <div
          className={`${styles.hd} ${
            loginType === LoginType.static ? styles.loginQuick : styles.loginStatic
          }`}
          style={{ height: 36 }}
        >
          <h2 className={styles.quick} onClick={this.loginSwitch}>
            快速登录
          </h2>
          {/* <h2 className="static" onClick={this.loginSwitch}>账密登录</h2> */}
          <div className={styles.quickTip}>
            <div className={styles.poptip}>
              <div className={styles.poptipArrow}>
                <em />
                <span />
              </div>
              <div className={styles.poptipContent}>扫码登录</div>
            </div>
          </div>
          {/* <div className="static-tip">
        <div className="poptip">
          <div className="poptip-arrow">
            <em></em>
            <span></span>
          </div>
          <div className="poptip-content">密码登录</div>
        </div>
      </div> */}
        </div>
        <div
          className={styles.loginQrcode}
          style={{ display: loginType === LoginType.quick ? "" : "none" }}
        >
          <div className={styles.title}>扫码登录</div>
          <div id="login_qrcode" className={styles.login_qrcode} />
        </div>
        {/* <div className="static-login" style={{display: loginType === LoginType.quick ? 'none' : ''}}>
      <div className="title">密码登录</div>
    </div> */}
      </div>
    );
  };

  private slideChange = swiper => {
    const { activeIndex } = swiper;
    const { orgList } = this.state;
    const orgsName = orgList[swiper.activeIndex]
      ? orgList[swiper.activeIndex].name
      : "请创建一个新的企业组织";
    const loginButtonName = orgList[swiper.activeIndex]
      ? LoginOrgType.login
      : LoginOrgType.registered;
    const tenantId = orgList[swiper.activeIndex] ? orgList[swiper.activeIndex].id : "";
    this.setState({ activeIndex, orgsName, loginButtonName, tenantId });
  };

  renderChooseTeam = () => {
    const { orgsName, activeIndex, loginButtonName, orgList } = this.state;
    const addOrgClass: IDictionary<boolean> = {};
    addOrgClass[styles.addLoginOrgItem] = true;
    addOrgClass[styles.newLoginOrgItemImgActive] = activeIndex === orgList.length;
    const swiperEvents: SwiperEvents = {
      slideChange: this.slideChange
    };
    const swiperOptions: ISwiperOptions = {
      slidesPerView: 3,
      spaceBetween: -30,
      centeredSlides: true,
      navigation: { nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }
    };
    return (
      <div className={styles.chooseTeam}>
        <div className={styles.newLoginOrgList}>
          <div className={styles.newLoginTitle}>请确认登录管理后台的企业</div>
          <div className={styles.newLoginOrgs}>
            <div className={styles.newLoginOrgsContent}>
              <DYSwiper
                ref={el => el && (this.swiper = el)}
                allowNoSwiping={true}
                on={swiperEvents}
                options={swiperOptions}
                extra={
                  <>
                    <Icon
                      type="antd-right"
                      className={`swiper-button-next ${styles.newLoginOrgsRight}`}
                    />
                    <Icon
                      type="antd-left"
                      className={`swiper-button-prev ${styles.newLoginOrgsLeft}`}
                    />
                  </>
                }
              >
                {orgList.map((org, index) => {
                  const icon = org.icon || "/img/org_icon.png";
                  const imgClass: IDictionary<boolean> = {};
                  imgClass[styles.newLoginOrgItemImg] = true;
                  imgClass[styles.newLoginOrgItemImgActive] = activeIndex === index;
                  const onClick = () => {
                    if (activeIndex > index) {
                      if (this.swiper) if (this.swiper.swiper) this.swiper.swiper.slidePrev();
                    } else if (activeIndex < index) {
                      if (this.swiper) if (this.swiper.swiper) this.swiper.swiper.slideNext();
                    }
                  };
                  if (typeof icon === "string") {
                    return (
                      <img
                        key={index}
                        src={icon}
                        className={classNames(imgClass)}
                        alt={org.name}
                        onClick={onClick}
                      />
                    );
                  } else {
                    return <React.Fragment key={index}>{icon}</React.Fragment>;
                  }
                })}
                <Icon
                  type="antd-plus-circle-fill"
                  className={classNames(addOrgClass)}
                  onClick={this.handleAddLoginOrg}
                />
              </DYSwiper>
            </div>
            <div className={styles.newLoginOrgsName}>{orgsName}</div>
          </div>
          <a className={styles.formGroup} onClick={this.handleLoginClick}>
            <div className={styles.loginButton}>{loginButtonName}</div>
          </a>
          <div className={styles.formGroup}>
            <a className={styles.returnButton} onClick={this.handleReturnClick}>
              返回
            </a>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { width, height, loginResultUrl, contextStatus } = this.state;
    return (
      <Layout className={styles.loginContainer} style={{ width, height }}>
        <Layout.Header className={`${styles.header}`}>
          <Logo />
          {/* {contextStatus === ContextStatusType.success && (
            <Link to="/registered" className={styles.registered}>
              企业注册
            </Link>
          )} */}
        </Layout.Header>
        <Layout.Content className={styles.loginBody}>
          {contextStatus === ContextStatusType.scan && this.renderLogin()}
          {contextStatus === ContextStatusType.success && this.renderChooseTeam()}
        </Layout.Content>
        <Footer />
        {loginResultUrl && <iframe src={loginResultUrl} width={0} height={0} frameBorder={0} />}
      </Layout>
    );
  }
}

export default Login;
