export enum LoginType {
  /** 快速登录 */
  quick,
  /** 账密登录 */
  static
}

export enum LoginOrgType {
  /** 登录 */
  login = "登录",
  /** 注册 */
  registered = "注册"
}

export enum ContextStatusType {
  /** 扫码 */
  scan,
  /** 扫码成功 */
  success
}

export interface IDYLoginStateTypes {
  orgsName?: string;
  tenantId?: string;
  loginButtonName: LoginOrgType;
  loginType: LoginType;
  orgList: IOrgList;
  loginResultUrl?: string;
  contextStatus: ContextStatusType;
  activeIndex: number;
  width: number;
  height: number;
}
