import * as React from "react";
import * as uuid from "node-uuid";
import classNames from "classnames";
import Swiper from 'swiper/dist/js/swiper.js'
import { SwiperEvent } from "swiper";
import "swiper/dist/css/swiper.min.css";
import { ISwiperProps, ISwiperStates, SwiperEvent as SwiperJsEvent } from "./ISwiperTypes";

class DYSwiper extends React.Component<ISwiperProps, ISwiperStates> {
  swiper: Swiper | undefined;
  constructor(props: ISwiperProps) {
    super(props);
    this.state = { container: `swiper-${uuid.v4().replace(/-/g, "")}` };
  }

  componentDidMount() {
    const { options, on } = this.props;
    const { container } = this.state;
    if (options) {
      if (!options.on) {
        if (on) {
          const swiperEvents: { [key in SwiperEvent]?: () => void } = {};
          for (const key in on) {
            if (on.hasOwnProperty(key)) {
              const event: SwiperJsEvent = on[key];
              swiperEvents[key] = () => {
                if (this.swiper) event(this.swiper);
              };
            }
          }
          options.on = { ...swiperEvents };
        }
      }
    }
    this.swiper = new Swiper(`.${container}`, options);
  }
  render() {
    const { children, extra, allowNoSwiping } = this.props;
    const { container } = this.state;
    const wrapperClass = classNames({ "swiper-wrapper": true, "swiper-no-swiping": allowNoSwiping });
    return (
      <div className={`swiper-container ${container}`}>
        <div className={wrapperClass}>
          {React.Children.map(children, child => {
            return <div className="swiper-slide">{child}</div>;
          })}
        </div>
        {extra}
      </div>
    );
  }
}

export default DYSwiper;
