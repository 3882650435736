import * as React from "react";
import * as styles from "./index.less";
import { Link } from "react-router-dom";

interface ILogoProps {
  logo?: string;
  name?: string;
}

class Logo extends React.Component<ILogoProps> {
  render() {
    const { logo, name } = this.props;
    return (
      <Link to="/" className={styles.logo}>
        <img src={logo || "/img/ydy_logo.svg"} alt="源钉云" />
        <span>{name || "源钉云管理后台"}</span>
      </Link>
    );
  }
}

export default Logo;
