import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

export interface IScrollToTopProps extends RouteComponentProps<IDictionary> {
  extraEvent?: (route: IScrollToTopProps) => void;
}

class ScrollToTop extends React.Component<IScrollToTopProps> {
  componentWillReceiveProps(prevProps: IScrollToTopProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
    if (prevProps.extraEvent) prevProps.extraEvent(prevProps);
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
