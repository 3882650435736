import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { getLocationSearch } from "rb-utility";
import MiddlegroundService from "../../Service";

type ILoginResultProps = RouteComponentProps<IDictionary>;

class LoginResult extends React.Component<ILoginResultProps> {
  constructor(props: ILoginResultProps) {
    super(props);
    const { code } = getLocationSearch();
    window.parent.postMessage({ code }, "*");
  }

  async componentDidMount() {
    if (self === top) {
      await MiddlegroundService.hasLoginAndOut(this.props);
    }
  }

  render() {
    return <React.Fragment />;
  }
}

export default LoginResult;
