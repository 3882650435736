import * as React from "react";
import "ant-design-pro/lib/GlobalFooter/style";
import * as styles from "./index.less";
import GlobalFooter from "ant-design-pro/lib/GlobalFooter";

const links: Array<{
  key?: string;
  title: React.ReactNode;
  href: string;
  blankTarget?: boolean;
}> = [
  {
    key: "帮助",
    title: "帮助",
    href: "https://www.yuque.com/yuandingyun",
    blankTarget: true
  },
  // {
  //   key: "条款",
  //   title: "条款",
  //   href: "/terms",
  //   blankTarget: true
  // }
];
const copyright: React.ReactNode = (
  <div>
    武汉源钉云互联科技有限公司 © 2018{" "}
    <a href="http://www.miitbeian.gov.cn" target="_blank" rel="noopener noreferrer">
      粤ICP备17044531号-3
    </a>
  </div>
);

interface IFooterProps {
  style?: React.CSSProperties;
}

class Footer extends React.Component<IFooterProps> {
  render() {
    const { style } = this.props;
    return (
      <div className={styles.footer} style={style}>
        <GlobalFooter links={links} copyright={copyright} />
      </div>
    );
  }
}

export default Footer;
