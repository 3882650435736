import { HttpService, ServiceHelper } from "rb-utility";
import { RouteComponentProps } from "react-router";

export enum TimeType {
  Year,
  Month,
  Day
}
export enum AppNormType {
  /** 试用 */
  TryOut,
  /** 开通 */
  Open
}
export enum AppStatus {
  /** 未开通 */
  nonactivated = 1,
  /** 已开通 */
  alreadyopened,
  /** 即将到期 */
  expiring,
  /** 已到期 */
  beexpired
}
/** 应用申请 */
export interface ITenantAppApplyParams {
  /** 应用ID */
  appId: string;
  /** 套餐ID */
  packageId: string;
  /**  申请使用时长 */
  useTo: number;
  timeType: TimeType;
}
export interface IAppInfo {
  id: string;
  name: string;
  icon: string;
  info: string;
  linkUrl: string;
  helpUrl: string;
  appCode: string;
  ipWhiteAddress: string;
  applyState: AppStatus;
  openOn: string;
  closeOn: string;
}
const systemId = "MutiTenancy";

export default class MiddlegroundService {
  private static _currentOrgNamed: string = sessionStorage.getItem("currentOrgNamed") || "";
  private static _userId: string = sessionStorage.getItem("userId") || "";
  private static _tenantId: string = sessionStorage.getItem("tenantId") || "";
  private static _appId: string = sessionStorage.getItem("appId") || "";
  private static _openId: string = sessionStorage.getItem("openId") || "";
  private static _userName: string = sessionStorage.getItem("userName") || "";

  public static get currentOrgNamed() {
    return MiddlegroundService._currentOrgNamed;
  }

  public static set currentOrgNamed(_currentOrgNamed: string) {
    if (baseConfig.development) sessionStorage.setItem("currentOrgNamed", _currentOrgNamed);
    this._currentOrgNamed = _currentOrgNamed;
  }

  public static get userId() {
    return MiddlegroundService._userId;
  }

  public static set userId(_userId: string) {
    if (baseConfig.development) sessionStorage.setItem("userId", _userId);
    this._userId = _userId;
  }

  public static get tenantId() {
    return MiddlegroundService._tenantId;
  }

  public static set tenantId(_tenantId: string) {
    if (baseConfig.development) sessionStorage.setItem("tenantId", _tenantId);
    this._tenantId = _tenantId;
  }

  public static get appId() {
    return MiddlegroundService._appId;
  }

  public static set appId(_appId: string) {
    if (baseConfig.development) sessionStorage.setItem("appId", _appId);
    this._appId = _appId;
  }

  public static get openId() {
    return MiddlegroundService._openId;
  }

  public static set openId(_openId: string) {
    if (baseConfig.development) sessionStorage.setItem("openId", _openId);
    this._openId = _openId;
  }

  public static get userName() {
    return MiddlegroundService._userName;
  }

  public static set userName(_userName: string) {
    if (baseConfig.development) sessionStorage.setItem("userName", _userName);
    this._userName = _userName;
  }

  /** 钉钉扫码登陆 */
  static async oauthLogin(code: string) {
    const result = await ServiceHelper.executeService<OauthLoginResult>(baseConfig.host, {
      name: "/service/dingoauth/oauthlogin",
      params: { code }
    });
    this.appId = result.appId;
    this.userId = result.userId;
    this.openId = result.openId;
    this.userName = result.userName;
    return result;
  }

  /** 获取企业管理员列表 */
  static async getAdminList(params: DingSecretInfo) {
    const { openId, userName } = this;
    const result = await ServiceHelper.executeService<AdminListResult>(baseConfig.host, {
      name: "/service/dingoauth/admins",
      params: { ...params, openId, userName }
    });
    console.log(result);
    // this.tenantId = result.tenantId;
    // this.currentOrgNamed = result.companyName;
    return result;
  }

  /** 创建企业 */
  static async Register(params:DingSecretInfo) {
    const { openId, userName } = this;
    const result = await ServiceHelper.executeService<RegisterResult>(baseConfig.host, {
      name: "/service/dingoauth/register",
      params: { ...params, openId, userName }
    });
    this.userId = result.userId;
    this.tenantId = result.tenantId;
    this.currentOrgNamed = result.companyName;
    return result;
  }

  /** 登陆 */
  static Login(tenantId: string, orgsName?: string) {
    this.tenantId = tenantId;
    const { appId, userId, userName } = this;
    if (orgsName) this.currentOrgNamed = orgsName;
    return ServiceHelper.executeService<any>(baseConfig.host, {
      name: "/service/ding/oauthlogin",
      params: { tenantId, appId, userId, userName }
    });
  }

  static async updataCompanyName() {
    const { tenantId } = this;
    const save = await HttpService.executeController<{ companyName: string }>(baseConfig.host, {
      id: "updataCompanyName",
      pageAddress: { pageMode: 2, pageId: "Main", moduleId: "Tenant", systemId },
      params: { tenantId }
    });
    if (save) MiddlegroundService.currentOrgNamed = save.companyName;
    return save;
  }

  /** 判断是否已登录 */
  private static hasLogin() {
    if (!this.userId || !this.tenantId || !this.appId) {
      return false;
    }
    return true;
  }

  static async hasLoginAndOut(route: RouteComponentProps<IDictionary>, path: string = "/login") {
    if (!MiddlegroundService.hasLogin()) {
      if (route.location.pathname.search("/registered") !== 0) {
        MiddlegroundService.handleLoginOut(route, path);
      }
    }
  }

  /** 登出 */
  static async handleLoginOut(route: RouteComponentProps<IDictionary>, path: string = "/login") {
    this.appId = "";
    this.tenantId = "";
    this.userId = "";
    this.userName = "";
    sessionStorage.clear();
    if (path !== route.location.pathname) {
      route.history.push(path);
    }
    return true;
  }

  /** 获取租户信息 */
  static async getTenant() {
    let filter: any[] = [];
    filter.push({
      dataType: 6,
      left: "id",
      op: 1,
      title: "租户ID",
      right: sessionStorage.getItem("tenantId")
    });
    return HttpService.getPageData<TenantParams>(baseConfig.host, {
      id: "租户",
      pageAddress: { pageMode: 0, pageId: "Main", moduleId: "Tenant", systemId },
      mode: 0,
      attachParams: {
        filter: {
          type: 1,
          expressions: filter
        }
      }
    });
  }

  /** 获取管理员信息 */
  static async getUser() {
    let filter: any[] = [];
    filter.push({
      dataType: 6,
      left: "id",
      op: 1,
      title: "管理员ID",
      right: sessionStorage.getItem("userId")
    });
    return HttpService.getPageData<UserParams>(baseConfig.host, {
      id: "管理员",
      pageAddress: { pageMode: 0, pageId: "Main", moduleId: "Tenant", systemId },
      mode: 0,
      attachParams: {
        filter: {
          type: 1,
          expressions: filter
        }
      }
    });
  }

  /** 获取管理员登陆信息 */
  static async getUserPassword() {
    let filter: any[] = [];
    filter.push({
      dataType: 6,
      left: "id",
      op: 1,
      title: "管理员ID",
      right: sessionStorage.getItem("userId")
    });
    return HttpService.getPageData<any>(baseConfig.host, {
      id: "管理员密码",
      pageAddress: { pageMode: 0, pageId: "Main", moduleId: "Tenant", systemId },
      mode: 0,
      attachParams: {
        filter: {
          type: 1,
          expressions: filter
        }
      }
    });
  }

  /** 获取钉钉授权信息 */
  static async getDingCorp() {
    let filter: any[] = [];
    filter.push({
      dataType: 6,
      left: "id",
      op: 1,
      title: "管理员ID",
      right: sessionStorage.getItem("userId")
    });
    return HttpService.getPageData<DingCorpResult>(baseConfig.host, {
      id: "钉钉授权",
      pageAddress: { pageMode: 0, pageId: "Main", moduleId: "DingTalk", systemId },
      mode: 0,
      attachParams: { id: this.tenantId }
    });
  }

  /**
   * 更新租户信息
   * @param params 需要更新租户数据
   */
  static saveTenant(params: TenantParams) {
    params["_dataState"] = 2;
    return HttpService.executeController<{ id: string }>(baseConfig.host, {
      id: "save",
      pageAddress: { pageMode: 2, pageId: "Main", moduleId: "Tenant", systemId },
      params: {
        tenant: params
      }
    });
  }

  /**
   * 更新管理员信息
   * @param userParams 需要更新管理员数据
   */
  static async saveUser(params: UserParams) {
    params["_dataState"] = 2;
    const save = await HttpService.executeController<{ id: string }>(baseConfig.host, {
      id: "saveUser",
      pageAddress: { pageMode: 2, pageId: "Main", moduleId: "Tenant", systemId },
      params: {
        user: { ...params, id: this.userId }
      }
    });
    return save;
  }

  /**
   * 更新租户信息
   * @param userPasswordParams 需要更新管理员登陆名和密码数据
   */
  static async saveUserPassword(params: UserPasswordParams) {
    params["_dataState"] = 2;
    let save = await HttpService.executeController<{ id: string }>(baseConfig.host, {
      id: "save",
      pageAddress: { pageMode: 2, pageId: "Main", moduleId: "Tenant", systemId },
      params: {
        _param_0: params
      }
    });
    return save;
  }

  /**
   * 更新租户授权信息
   * @param userPasswordParams 需要更新管理员登陆名和密码数据
   */
  static async saveDingCorp(params: DingCorpParams) {
    const { tenantId } = this;
    params["_dataState"] = 2;
    // params['tenantId'] = sessionStorage.getItem('userTenantId');
    let save = await HttpService.executeController<{ id: string }>(baseConfig.host, {
      id: "save",
      pageAddress: { pageMode: 2, pageId: "Main", moduleId: "DingTalk", systemId },
      params: {
        corp: { ...params, tenantId }
      }
    });
    return save;
  }

  /** 获取应用信息列表 */
  static async getAppList() {
    return HttpService.getPageData<IAppInfo[]>(baseConfig.host, {
      id: "应用",
      pageAddress: {
        pageMode: 0,
        pageId: "Main",
        moduleId: "Application",
        systemId
      },
      mode: 1,
      attachParams: { tenantId: this.tenantId }
    });
  }

  static async getAppNormList(appId: string) {
    return HttpService.getPageData<AppNormInfo[]>(baseConfig.host, {
      id: "应用套餐",
      pageAddress: {
        pageMode: 0,
        pageId: "Main",
        moduleId: "Application",
        systemId
      },
      mode: 1,
      attachParams: { appId }
    });
  }

  /**
   * 保存租户应用申请
   * @param TenantAppApplyInfo 需要保存租户应用申请数据
   */
  static async saveTenantAppApply(params: ITenantAppApplyParams) {
    const { tenantId, userId } = this;
    params["_dataState"] = 2;
    // params['tenantId'] = sessionStorage.getItem('userTenantId')
    let save = await HttpService.executeController<{ id: string }>(baseConfig.host, {
      id: "saveTenantAppApply",
      pageAddress: {
        pageMode: 2,
        pageId: "Main",
        moduleId: "Application",
        systemId
      },
      params: {
        entity: { tenantId, ...params, applyBy: userId, applyOn: new Date(), applyState: 5 }
      }
    });
    return save;
  }
}
